import { api } from './api.service';

export const contactService = {
  sendEmail
};

const endpoint = '/webpage/contact'

async function sendEmail(data) {
  return await api._post(endpoint, data);
}