import '../style.css';
import backImage from '../../../assets/images/image-desktop.png'
import logo from '../../../assets/images/logo.png'
import Form from './form';

const Contact = () => {
    return (
        <div className='Container-Back-Image'>
            <img src={backImage} className="Back-Image" alt="personas" />
            <div className='container-form'>
                <img src={logo} className="logo" alt="logo" />
                <p className='text-welcome'>En <b>Universal Seguros</b>, estamos trabajando para tener a tu disposición un portal de seguros preparado para que <b>cuides de ti y tu familia.</b></p>
                <Form />
                <div>
                    <p className='text-construction'>...en construcción</p>
                    <p className='text-universalseguros'>universalseguros</p>
                    <p className='text-co'>.co</p>
                </div>
            </div>
        </div>
    );
}

export default Contact;
