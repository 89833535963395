import Footer from './pages/footer/components/footer'
import Contact from './pages/contact/components/contact.jsx'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
