import '../style.css';

const Footer = () => {
    return (
      <div className="Container-Footer">
        <p className='text-footer'>Todos los derechos reservados - Universal de seguros - 2022</p>
      </div>
    );
  }
  
  export default Footer;
  