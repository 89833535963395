//import { Auth } from 'aws-amplify';

export const api = {
  _get,
  _post,
  _delete,
  _put,
  _postFile
};

const url = "https://5zzmpdutqf.execute-api.us-east-1.amazonaws.com/production";// process.env.REACT_APP_URL_API;

async function _get(path) {
  //const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      //Authorization: token
    }
  };
  return fetch(`${url}${path}`, requestOptions).then(handleResponse);
}

async function _postFile(path, body) {
  //const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
  const requestOptions = {
    method: "POST",
    body,
    headers: {
    //   Authorization: token
    }
  };
  return fetch(`${url}${path}`, requestOptions).then(handleResponse);
}

async function _post(path, body) {
  //const token = (await Auth.currentSession()).getAccessToken().getJwtToken();//localStorage.getItem("token-universal-bot");
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    //   "Authorization": token
    }
  };
  return fetch(`${url}${path}`, requestOptions).then(handleResponse);
}

async function _delete(path, body) {
//   const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
  const requestOptions = {
    method: "DELETE",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    //   Authorization: token
    }
  };
  return fetch(`${url}${path}`, requestOptions).then(handleResponse);
}

async function _put(path, body) {
//   const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    //   Authorization: token
    }
  };
  return fetch(`${url}${path}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        window.location.reload(true);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}