import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { contactService } from '../../../services/contact.service';
import Alert from '../../../common/components/alert';
import Modal from 'react-bootstrap/Modal';

const FormContact = () => {

    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [observations, setObservations] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSubmit = async (event) => {
        event.preventDefault();
       const data = await contactService.sendEmail({ name, lastName, email, phoneNumber, observations }).then(
            response => {
                handleShow();
            },
            error => {
                alert("Error: " + error);
            }
        );
       // console.log("data",data)
        cleanFields();
    }

    const cleanFields = () => {
        setName('');
        setLastName('');
        setEmail('');
        setPhoneNumber('');
        setObservations('');
    }

    return (
        <Form className='form' onSubmit={event => handleSubmit(event)} >
            <Row className="mb-3" sm={"12"}>
                <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control type="text" required placeholder="ingresa el nombre" onChange={(event) => setName(event.target.value)} />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Label>Apellidos</Form.Label>
                    <Form.Control type="text" placeholder="ingresa el apellido" onChange={(event) => setLastName(event.target.value)} />
                </Form.Group>
            </Row>
            <Row className="mb-3" sm={"12"}>
                <Form.Group as={Col} className="mb-3" controlId="formGridAddress1">
                    <Form.Label>Correo electrónico</Form.Label>
                    <Form.Control type="email" required placeholder="ingresa el correo electrónico" onChange={(event) => setEmail(event.target.value)} />
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="formGridAddress2">
                    <Form.Label>Número de celular</Form.Label>
                    <Form.Control type='number' placeholder="ingresa el número de celular" onChange={(event) => setPhoneNumber(event.target.value)} />
                </Form.Group>
            </Row>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Observaciones</Form.Label>
                <Form.Control placeholder='observaciones' as="textarea" rows={3} onChange={(event) => setObservations(event.target.value)} />
            </Form.Group>
            <div className='div-buttons'>
                <Button variant="outline-primary" className='button-cancel'>
                    Cancelar
                </Button>
                <Button className='button-acept' variant="primary" type="submit">
                    Enviar
                </Button>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Universal Seguros</Modal.Title>
                </Modal.Header>
                <Modal.Body>Tus datos fueron enviados correctamente. Pronto te estaremos contactando!</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>
        </Form>
    )
}

export default FormContact;